export default function prepareAlertRules(values) {
  const { name, alert_rule_0, alert_rule_1, alert_rule_2, alert_rule_3, alert_rule_4, message_type } = values;
  let alert_rules = null;

  const rule_0 = prepareRuleValue(alert_rule_0);
  if (rule_0) alert_rules = [rule_0];
  if (message_type === "temphumid" && alert_rule_1) {
    const rule_1 = prepareRuleValue(alert_rule_1);
    if (rule_1) alert_rules ? alert_rules.push(rule_1) : (alert_rules = [null, rule_1]);
  }

  if (message_type === "temphumidco2" && (alert_rule_1 || alert_rule_2)) {
    if (alert_rule_1) {
      const rule_1 = prepareRuleValue(alert_rule_1);
      if (rule_1) alert_rules ? alert_rules.push(rule_1) : (alert_rules = [null, rule_1, null]);
    }

    if (alert_rule_2) {
      const rule_2 = prepareRuleValue(alert_rule_2);
      if (rule_2) alert_rules ? alert_rules.push(rule_2) : (alert_rules = [null, null, rule_2]);
    }
  }

  if (message_type === "tilt" && (alert_rule_1 || alert_rule_2 || alert_rule_3 || alert_rule_4)) {
    if (alert_rule_1) {
      const rule_1 = prepareRuleValue(alert_rule_1);
      if (rule_1) alert_rules ? alert_rules.push(rule_1) : (alert_rules = [null, rule_1, null, null, null]);
    }
    if (alert_rule_2) {
      const rule_2 = prepareRuleValue(alert_rule_2);
      if (rule_2) alert_rules ? alert_rules.push(rule_2) : (alert_rules = [null, null, rule_2, null, null]);
    }
    if (alert_rule_3) {
      const rule_3 = prepareRuleValue(alert_rule_3);
      if (rule_3) alert_rules ? alert_rules.push(rule_3) : (alert_rules = [null, null, null, rule_3, null]);
    }
    if (alert_rule_4) {
      const rule_4 = prepareRuleValue(alert_rule_4);
      if (rule_4) alert_rules ? alert_rules.push(rule_4) : (alert_rules = [null, null, null, null, rule_4]);
    }
  }
  return { name, alert_rules };
}

const prepareRuleValue = rule => {
  if (!rule || (!rule.lower_bound && !rule.upper_bound)) return null;
  rule.bounding = "[]";
  rule.inclusion = "e";
  return rule;
};
