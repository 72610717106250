import React from 'react';
import moment from "moment";
import numeral from "numeraljs";


const X_AXIS_FORMAT = {
  "4_hours": "DD.MM.YY HH:MM",
  "24_hours": "DD.MM.YY HH:MM",
  "7_days": "DD.MM.YY",
  "30_days": "DD.MM.YY"
};


export class YAxisTick extends React.Component {
  render() {
    const {x, y, payload, format, textAnchor} = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor={textAnchor}
          fill="#666"
          // transform="rotate(-35)">
        >
          {numeral(payload.value).format(format)}
        </text>
      </g>
    );
  }
}

export class XAxisTick extends React.Component {
  render() {
    const {x, y, payload, range} = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#666"
          // transform="rotate(-35)">
        >
          {moment(payload.value).format(X_AXIS_FORMAT[range])}
        </text>
      </g>
    );
  }
}