const addDefaultDeviceParameters = values => {
  values.message_format.msg_length = 24;
  if (["temperature", "temphumid", "temphumidco2"].includes(values.message_format.type)) {
    if (values.id.length === 15) {
      values.message_format.measurement_count = 1;
      values.message_format.msg_length = 6;
    } else {
      values.message_format.measurement_count = 4;
    }
    values.meter_type = values.message_format.type;
  }
  if (["tilt"].includes(values.message_format.type)) {
    values.message_format.measurement_count = 1;
    values.message_format.msg_length = 12; // 6 bytes
    values.meter_type = values.message_format.type;
  }
  return values;
};

export default addDefaultDeviceParameters;
