import React from "react";

import {
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
  ReferenceArea,
  Label
} from "recharts";

import { XAxisTick, YAxisTick } from "../axisTicks";
import ChartZoomController from "../ChartZoomController";
import { tooltipFormatter } from "../tooltipFormatter";

class ValueLineChart extends React.Component {
  render() {
    const { range = "4_hours", data, height, canZoomIn, loadData } = this.props;
    const domainX = this.props['domainX'] instanceof Array ? this.props['domainX'] : ["dataMin", "dataMax"];
    const domainY = this.props['domainY'] instanceof Array ? this.props['domainY'] : ["dataMin-1", "dataMax+1"];
    return (
      <ChartZoomController data={data} loadData={loadData} canZoomIn={canZoomIn}>
        {({ zoomAxis, ...mouseEventsHandlers }) => (
          <ResponsiveContainer height={height}>
            <ComposedChart
              data={data}
              margin={{ top: 20, right: 0, left: 20, bottom: 0 }}
              style={{ fontSize: "0.7rem" }}
              {...mouseEventsHandlers}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                padding={{ left: 20, right: 20 }}
                allowDataOverflow={true}
                dataKey="time"
                domain={domainX}
                type="category"
                tick={<XAxisTick range={range} />}
              />
              <YAxis
                padding={{ top: 10, bottom: 10 }}
                allowDataOverflow={true}
                domain={domainY}
                type="number"
                yAxisId="1"
                tick={<YAxisTick format={"0,0.00"} textAnchor="end" />}
              >
                <Label value="Value" offset={0} position="top" />
              </YAxis>
              <Tooltip formatter={tooltipFormatter} />
              <Line
                yAxisId="1"
                type="linear"
                dataKey="Value"
                stroke="#3DB7DA"
                strokeWidth="2"
                animationDuration={200}
                dot={<DotWithFallback />}
              />
              {zoomAxis[0] && zoomAxis[1] ? (
                <ReferenceArea yAxisId="1" x1={zoomAxis[0]} x2={zoomAxis[1]} strokeOpacity={0.3} />
              ) : null}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </ChartZoomController>
    );
  }
}

export default ValueLineChart;

const DotWithFallback = (props) => {
  const { cx, cy, stroke, payload } = props;

  if (payload.Fallback === true) {
    return (
      <rect x={cx - 2} y={cy - 2} width={4} height={4} fill={"red"} strokeWidth={"2"} />
    );
  }

  return (
      <circle cx={cx} cy={cy} r={2} fill={stroke} />
  );
};