import React from "react";

import { TextInput, NumberInput } from "react-admin";

import { required, number } from "react-admin";
import { extractRest } from "../../../utils/extractRest";

function alertRuleName(msgType) {
  if (msgType === "temperature") return ["Temperature alerts"];
  if (msgType === "temphumid") return ["Temperature alerts", "Humidity alerts"];
  if (msgType === "temphumidco2") return ["Temperature alerts", "Humidity alerts", "CO2 alerts"];
  if (msgType === "tilt") return ["Distance alerts", "X alerts", "Y alerts", "Z alerts"];
  if (msgType === "incremental") return ["Rate alerts"];
  return ["Value change alerts"];
}

function RuleTitle({ msgType, idx = 0 }) {
  const title = alertRuleName(msgType)[idx];
  return (
    <div>
      <div className="form-section-title">{title} settings</div>
      <p className="m-0">Define lower and upper values for {title.toLowerCase()}.</p>
    </div>
  );
}

const MonitorEditFormFields = props => {
  const restProps = extractRest(props);
  const {
    record: { message_type }
  } = props;
  return (
    <div className="form-fields">
      <div className="form-section-title">Name</div>
      <div className="form-row">
        <div className="w-100">
          <TextInput
            className="m-0"
            label="Edit device name"
            source="name"
            validate={required()}
            fullWidth
            {...restProps}
          />
        </div>
      </div>
      <RuleTitle msgType={message_type} />
      <div className="form-row">
        <div className="w-100 w-45-m">
          <NumberInput
            label="Lower bound"
            source="alert_rule_0[lower_bound]"
            validate={number()}
            helperText="Empty field means ‘-infinity’."
            fullWidth
            {...restProps}
          />
        </div>
        <div className="w-100 w-45-m">
          <NumberInput
            label="Upper bound"
            source="alert_rule_0[upper_bound]"
            validate={number()}
            helperText="Empty field means ‘+infinity’."
            fullWidth
            {...restProps}
          />
        </div>
      </div>
      {message_type === "temphumid" && (
        <div>
          <RuleTitle msgType={message_type} idx={1} />
          <div className="form-row">
            <div className="w-100 w-45-m">
              <NumberInput
                label="Lower bound"
                source="alert_rule_1[lower_bound]"
                validate={number()}
                helperText="Empty field means ‘-infinity’."
                fullWidth
                {...restProps}
              />
            </div>
            <div className="w-100 w-45-m">
              <NumberInput
                label="Upper bound"
                source="alert_rule_1[upper_bound]"
                validate={number()}
                helperText="Empty field means ‘+infinity’."
                fullWidth
                {...restProps}
              />
            </div>
          </div>
        </div>
      )}
        {message_type === "temphumidco2" && (
            <div>
                <RuleTitle msgType={message_type} idx={1} />
                <div className="form-row">
                    <div className="w-100 w-45-m">
                        <NumberInput
                            label="Lower bound"
                            source="alert_rule_1[lower_bound]"
                            validate={number()}
                            helperText="Empty field means ‘-infinity’."
                            fullWidth
                            {...restProps}
                        />
                    </div>
                    <div className="w-100 w-45-m">
                        <NumberInput
                            label="Upper bound"
                            source="alert_rule_1[upper_bound]"
                            validate={number()}
                            helperText="Empty field means ‘+infinity’."
                            fullWidth
                            {...restProps}
                        />
                    </div>
                </div>
                <RuleTitle msgType={message_type} idx={2} />
                <div className="form-row">
                    <div className="w-100 w-45-m">
                        <NumberInput
                            label="Lower bound"
                            source="alert_rule_2[lower_bound]"
                            validate={number()}
                            helperText="Empty field means ‘-infinity’."
                            fullWidth
                            {...restProps}
                        />
                    </div>
                    <div className="w-100 w-45-m">
                        <NumberInput
                            label="Upper bound"
                            source="alert_rule_2[upper_bound]"
                            validate={number()}
                            helperText="Empty field means ‘+infinity’."
                            fullWidth
                            {...restProps}
                        />
                    </div>
                </div>
            </div>
        )}
        {message_type === "tilt" && (
            <div>
                <RuleTitle msgType={message_type} idx={1} />
                <div className="form-row">
                    <div className="w-100 w-45-m">
                        <NumberInput
                            label="Lower bound"
                            source="alert_rule_1[lower_bound]"
                            validate={number()}
                            helperText="Empty field means ‘-infinity’."
                            fullWidth
                            {...restProps}
                        />
                    </div>
                    <div className="w-100 w-45-m">
                        <NumberInput
                            label="Upper bound"
                            source="alert_rule_1[upper_bound]"
                            validate={number()}
                            helperText="Empty field means ‘+infinity’."
                            fullWidth
                            {...restProps}
                        />
                    </div>
                </div>
                <RuleTitle msgType={message_type} idx={2} />
                <div className="form-row">
                    <div className="w-100 w-45-m">
                        <NumberInput
                            label="Lower bound"
                            source="alert_rule_2[lower_bound]"
                            validate={number()}
                            helperText="Empty field means ‘-infinity’."
                            fullWidth
                            {...restProps}
                        />
                    </div>
                    <div className="w-100 w-45-m">
                        <NumberInput
                            label="Upper bound"
                            source="alert_rule_2[upper_bound]"
                            validate={number()}
                            helperText="Empty field means ‘+infinity’."
                            fullWidth
                            {...restProps}
                        />
                    </div>
                </div>
                <RuleTitle msgType={message_type} idx={3} />
                <div className="form-row">
                    <div className="w-100 w-45-m">
                        <NumberInput
                            label="Lower bound"
                            source="alert_rule_3[lower_bound]"
                            validate={number()}
                            helperText="Empty field means ‘-infinity’."
                            fullWidth
                            {...restProps}
                        />
                    </div>
                    <div className="w-100 w-45-m">
                        <NumberInput
                            label="Upper bound"
                            source="alert_rule_3[upper_bound]"
                            validate={number()}
                            helperText="Empty field means ‘+infinity’."
                            fullWidth
                            {...restProps}
                        />
                    </div>
                </div>
            </div>
        )}
    </div>
  );
};

export default MonitorEditFormFields;
