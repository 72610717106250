import React from "react";
import DeviceAndTime from "./DeviceAndTime";
import ValueWithLabel, { formattedValue } from "./ValueWithLabel";
import { tenCharacterNumber } from "../../Charts/tooltipFormatter";

export default function TiltMeter(props) {
  const {
    aggregate: { values = [] }
  } = props;
  const [distance, tilt_x, tilt_y, tilt_z, fallback] = values;

  //console.log(props);

  return (
    <>
      <div className="absolute-container">
        <DeviceAndTime {...props} />
        <div className="flex-cent basic-data">
          <div className="meter-value" style={{ color: (distance === -1 || fallback === true ? "red" : "") }}>
            <span style={{ letterSpacing: "0.6vw" }}>
              {tenCharacterNumber(distance)}
            </span>
            <span
              style={{
                letterSpacing: "initial",
                marginLeft: "5px"
              }}
            >
              {props.uom || 'cm'}
            </span>
          </div>
        </div>
          <div className="tilt-data">
            <div className="values-column">
              <ValueWithLabel label="X" className="meter-value">
                <div>{formattedValue(tilt_x, 0, "°")}</div>
              </ValueWithLabel>
              <ValueWithLabel label="Y" className="meter-value">
                <div>{formattedValue(tilt_y, 0, "°")}</div>
              </ValueWithLabel>
              <ValueWithLabel label="Z" className="meter-value">
                <div>{formattedValue(tilt_z, 0, "°")}</div>
              </ValueWithLabel>
            </div>
          </div>
      </div>
    </>
  );
}
