import React from "react";
import moment from "moment";
import { DISPLAY_TIME_FORMAT } from "../ChartDataController";
import ValueLineChart from "../BasicIncrementalChartContainer/ValueLineChart";
import TiltChart from "./TiltChart";
import {fillSerieWithNonNegativeValue} from "../aggregators";

export default function TiltChartContainer({ data, loadData, params, bucket }) {
  const [rule_d, rule_x, rule_y, rule_z] = params.alert_rules || [];
  const { lower_bound: lowerBoundD, upper_bound: upperBoundD } = rule_d || {};

  if (data !== undefined && data !== null) {
    data = fillSerieWithNonNegativeValue(data, 'distance', false) || [];
  }
  const chartData = data.map(d => {
    return {
      bucket: d.bucket,
      time: moment(d.time).format(DISPLAY_TIME_FORMAT),
      Value: d.distance,
      "Lower bound": lowerBoundD,
      "Upper bound": upperBoundD,
      Fallback: d.fallback
    };
  });

  const tiltData = data.map(d => {
    return {
      bucket: d.bucket,
      time: moment(d.time).format(DISPLAY_TIME_FORMAT),
      tilt_x: d.tilt_x,
      tilt_y: d.tilt_y,
      tilt_z: d.tilt_z,
      Fallback: d.fallback
    };
  });
  return (
    <div style={{ width: "99%" }}>
      <ValueLineChart
        height={260}
        data={chartData}
        loadData={loadData}
        domainY={[0, 'dataMax+1']}
        canZoomIn={bucket >= params.measure_interval}
      />
      <TiltChart
        height={160}
        data={tiltData}
        loadData={loadData}
        canZoomIn={bucket >= params.measure_interval}
        message_type={params.message_type}
        boundaries={{tilt_x: rule_x || {}, tilt_y: rule_y || {}, tilt_z: rule_z || {}}}
      />
    </div>
  );
}
