import React from "react";

import {
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
  ReferenceArea,
  Label
} from "recharts";

import { XAxisTick, YAxisTick } from "../axisTicks";
import ChartZoomController from "../ChartZoomController";
import { tooltipFormatter } from "../tooltipFormatter";

class TiltChart extends React.Component {
  render() {
    const { range = "4_hours", data, height, canZoomIn, loadData, boundaries } = this.props;
    return (
      <ChartZoomController
        data={data}
        loadData={loadData}
        canZoomIn={canZoomIn}
      >
        {({ zoomAxis, ...mouseEventsHandlers }) => (
          <ResponsiveContainer height={height}>
            <ComposedChart
              data={data}
              margin={{ top: 20, right: 0, left: 20, bottom: 0 }}
              style={{ fontSize: "0.7rem" }}
              {...mouseEventsHandlers}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                padding={{ left: 20, right: 20 }}
                allowDataOverflow={true}
                dataKey="time"
                domain={["dataMin", "dataMax"]}
                type="category"
                tick={<XAxisTick range={range} />}
              />
              <YAxis
                padding={{ top: 10, bottom: 10 }}
                allowDataOverflow={true}
                domain={["dataMin-1", "dataMax+1"]}
                type="number"
                yAxisId="1"
                tick={<YAxisTick format={"0,0.0"} textAnchor="end" />}
              >
                <Label value="Tilt " offset={0} position="top" />
              </YAxis>
              <Tooltip
                wrapperClassName="temperature-tooltip-item"
                formatter={tooltipFormatter}
              />
              <Line
                yAxisId="1"
                type="monotoneX"
                dataKey="tilt_x"
                stroke="#264653"
                strokeWidth="2"
                animationDuration={200}
                dot={<CustomizedDot lower={boundaries.tilt_x.lower_bound} upper={boundaries.tilt_x.upper_bound} />}
              />
              <Line
                yAxisId="1"
                type="monotoneX"
                dataKey="tilt_y"
                stroke="#2A9D8F"
                strokeWidth="2"
                animationDuration={200}
                dot={<CustomizedDot lower={boundaries.tilt_y.lower_bound} upper={boundaries.tilt_y.upper_bound} />}
              />
              <Line
                yAxisId="1"
                type="monotoneX"
                dataKey="tilt_z"
                stroke="#E9C46A"
                strokeWidth="2"
                animationDuration={200}
                dot={<CustomizedDot lower={boundaries.tilt_z.lower_bound} upper={boundaries.tilt_z.upper_bound} />}
              />
              {zoomAxis[0] && zoomAxis[1] ? (
                <ReferenceArea
                  yAxisId="1"
                  x1={zoomAxis[0]}
                  x2={zoomAxis[1]}
                  strokeOpacity={0.3}
                />
              ) : null}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </ChartZoomController>
    );
  }
}

export default TiltChart;

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value, lower, upper } = props;

  if (payload.Fallback === true) {
    return (
      <rect x={cx - 2} y={cy - 2} width={4} height={4} fill={"red"} strokeWidth={"2"} />
    );
  }

  if (upper !== undefined && value > upper) {
    return (
      <circle cx={cx} cy={cy} r={2} fill={"red"} />
    );
  }
  if (lower !== undefined && value < lower) {
    return (
      <circle cx={cx} cy={cy} r={2} fill={"red"}/>
    );
  }

  return (
    <circle cx={cx} cy={cy} r={2} fill={stroke} />
  );
};