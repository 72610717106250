import React from "react";
import RefreshIcon from "@material-ui/icons/Cached";
import { Button } from "react-admin";

import ChartNavController from "./ChartNavController";
import ChartDataController from "./ChartDataController";
import ChartNavButton from "./ChartNavButton";
import { noMobilePadding } from "../MonitorList";
import { withStyles } from "@material-ui/core";
import BasicIncrementalChartContainer from "./BasicIncrementalChartContainer";
import TemperatureChartContainer from "./TemperatureChartContainer";
import TemphumidChartContainer from "./TemphumidChartContainer";
import Temphumidco2ChartContainer from "./Temphumidco2ChartContainer";
import TiltChartContainer from "./TiltChartContainer";

const RANGES = ["30_days", "7_days", "24_hours", "4_hours"];

const styles = theme => ({
  buttons: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.675rem",
      ...noMobilePadding
    }
  }
});

class Charts extends React.Component {
  chartControllerRef = React.createRef();

  loadCurrentRange = () => {
    const { current } = this.chartControllerRef;
    return current && current.loadCurrentRange();
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps.params) !== JSON.stringify(this.props.params);
  }

  chartFactory = (message_type, params) => {
    if (message_type === "temperature") return TemperatureChartContainer(params);
    if (message_type === "temphumid") return TemphumidChartContainer(params);
    if (message_type === "temphumidco2") return <Temphumidco2ChartContainer data={params.data} bucket={params.bucket} loadData={params.loadData} params={params.params} />
    if (message_type === "tilt") return TiltChartContainer(params);
    return BasicIncrementalChartContainer(params);
  };

  render() {
    const { params, onNewData } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <ChartNavController initialNav="4_hours">
          {({ activeNav, updateNav }) => (
            <>
              <div className={this.props.classes.buttons}>
                {RANGES.map(range => (
                  <ChartNavButton
                    key={range}
                    text={range}
                    active={activeNav === range}
                    onClick={updateNav(range)}
                  />
                ))}
                <Button variant="flat" onClick={this.loadCurrentRange}>
                  <RefreshIcon />
                </Button>
              </div>
              <ChartDataController
                params={params}
                currentRange={activeNav}
                onNewData={onNewData}
                ref={this.chartControllerRef}
              >
                {({ data, bucket, loadData }) =>
                  this.chartFactory(params.message_type, { data, bucket, loadData, params })
                }
              </ChartDataController>
            </>
          )}
        </ChartNavController>
      </div>
    );
  }
}

export default withStyles(styles)(Charts);
