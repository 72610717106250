import React from "react";

import {
  TextInput,
  SelectInput,
  ReferenceInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  DisabledInput
} from "react-admin";

import { required, choices, maxLength, number, minValue } from "react-admin";
import BasicFormatFields from "./BasicFormatFields";
import IncrementalFormatFields from "./IncrementalFormatFields";
import { extractRest } from "../../../utils/extractRest";
import { validatePsc } from "../../../validations/validatePsc";

export function configurableMsgType(msgFormat = {}) {
  const { type } = msgFormat;
  return ["basic", "incremental"].includes(type);
}

export const messageFormats = ["basic", "incremental", "temperature", "temphumid", "temphumidco2", "tilt"];
export const messageFormatsOptions = [
  { id: "basic", name: "Basic" },
  { id: "incremental", name: "Incremental" },
  { id: "temperature", name: "Temperature" },
  { id: "temphumid", name: "Temperature+Humidity" },
  { id: "temphumidco2", name: "Temperature+Humidity+CO2" },
  { id: "tilt", name: "Distance+Tilt" }
];

export function MessageFormatConfig({ msgFormat = {}, ...fieldsProps }) {
  const { type } = msgFormat;
  return (
    <>
      {type === "basic" && <BasicFormatFields {...fieldsProps} />}
      {type === "incremental" && <IncrementalFormatFields {...fieldsProps} />}
      {!configurableMsgType(msgFormat)}
    </>
  );
}

const DeviceFormFields = props => {
  const restProps = extractRest(props);
  return (
    <div className="form-fields">
      <div className="form-section-title">Registration data</div>
      <div className="form-row">
        <div className="w-100 w-45-m">
          {props.forCreate ? (
            <TextInput
              source="id"
              label="Device ID"
              className="m-0"
              fullWidth
              validate={required()}
              {...restProps}
            />
          ) : (
            <DisabledInput
              source="id"
              label="Device ID"
              className="m-0"
              fullWidth
              validate={required()}
              {...restProps}
            />
          )}
        </div>
        <div className="w-100 w-45-m">
          {props.forCreate ? (
            <TextInput
              source="psc"
              label="PSC"
              className="m-0"
              fullWidth
              validate={validatePsc}
              {...restProps}
            />
          ) : (
            <DisabledInput
              source="psc"
              label="PSC"
              className="m-0"
              fullWidth
              validate={validatePsc}
              {...restProps}
            />
          )}
        </div>

        <div className="w-100 w-45-m">
          <TextInput
            source="uom"
            label="Unit of measure"
            fullWidth
            validate={[maxLength(7)]}
            {...restProps}
          />
        </div>
      </div>

      <div className="form-section-title">Name & type</div>
      <div className="form-row">
        <div className="w-100 w-45-m">
          <TextInput
            source="name"
            label="Device name"
            className="m-0"
            fullWidth
            validate={required()}
            {...restProps}
          />
        </div>

        <div className="w-100 w-45-m">
          <FormDataConsumer {...restProps}>
            {({ formData, ...rest }) =>
              configurableMsgType(formData.message_format) && (
                <ReferenceInput
                  label="Meter Type"
                  source="meter_type"
                  reference="meter_types"
                  className="m-0"
                  fullWidth
                  validate={required()}
                  {...restProps}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
        </div>
      </div>

      <div className="form-section-title">Data format</div>
      <div className="w-100">
        <RadioButtonGroupInput
          source="message_format[type]"
          label=""
          className="m-0"
          fullWidth
          validate={choices(messageFormats, "is required")}
          defaultValue={"basic"}
          choices={messageFormatsOptions}
          {...restProps}
        />
      </div>

      <FormDataConsumer {...restProps}>
        {/*
          redux is not clearing fields when @@redux-form/UNREGISTER_FIELD
          https://github.com/erikras/redux-form/issues/2032

          TODO: fix with own create action which can filter sent fields
          FIX: https://marmelab.com/react-admin/Actions.html#altering-the-form-values-before-submitting
      */}
        {({ formData, ...rest }) => (
          <MessageFormatConfig msgFormat={formData.message_format} {...rest} {...restProps} />
        )}
      </FormDataConsumer>

      <div className="form-row">
        <div className="w-100 w-45-m">
          <ReferenceInput
            label="Transmit interval"
            source="transmit_interval"
            reference="transmit_intervals"
            className="m-0"
            fullWidth
            validate={required()}
            {...restProps}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </div>
      </div>

      <div className="form-section-title">Value adjustments</div>
      <div className="form-row">
        <div className="w-100 w-45-m">
          <TextInput
            source="multiplier"
            label="Multiplier"
            className="m-0"
            fullWidth
            validate={[number(), minValue(0)]}
            {...restProps}
          />
        </div>
        <div className="w-100 w-45-m">
          <TextInput
            source="shift"
            label="Shift"
            className="m-0"
            fullWidth
            validate={number()}
            {...restProps}
          />
        </div>
      </div>
    </div>
  );
};

export default DeviceFormFields;
